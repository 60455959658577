.maintain-content {
  padding: 30px 20px;
  font-size: 15px;
  line-height: 20px;
  text-align: center
}

.maintain-content h2 {
  font-size: 25px;
  line-height: 30px;
  margin-bottom: 10px
}

* {
  margin: 0;
  padding: 0
}

html,
body {
  height: 100%;
  font-family: Tahoma, Helvetica, sans-serif;
  color: #1E1E1E;
  font-size: 12px;
  line-height: 15px;
  background: #252525
}

.login-wrap,
.maintain-wrap {
  position: absolute;
  top: 17%;
  left: 50%;
  width: 540px;
  height: 408px;
  border-radius: 8px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
  margin-left: -270px;
  zoom: 1
}

.maintain-wrap {
  width: 480px;
  height: auto;
  background-color: #fff;
  margin-left: -240px
}

.maintain-content {
  height: auto !important;
  *height: 170px;
  min-height: 170px
}

.maintain-content>p {
  width: 340px;
  margin: 0 auto
}

.time-space {
  width: 120px;
  color: #2789CE;
  margin: 15px auto 10px
}

.time-space p {
  line-height: 40px;
  font-size: 19px;
  font-weight: bold;
  width: 140px;
  margin-bottom: 3px;
  border-radius: 20px;
  border: 1px solid #2789CE;
}

.time-space span {
  font-size: 12px;
  line-height: 14px
}

@media only screen and (max-device-width: 768px) {
  body {
      font-size: 4.26667vw;
      line-height: 5.33333vw
  }
  .maintain-wrap {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 86%;
      margin: 0 auto
  }
  .maintain-content {
      width: 100%;
      min-height: auto;
      padding: 8.53333vw 2.13333vw;
      font-size: 4.26667vw;
      line-height: 5.33333vw
  }
  .maintain-content h2 {
      margin-bottom: 4.26667vw;
      font-size: 6.4vw;
      line-height: 6.4vw
  }
  .maintain-content>p {
      width: auto
  }
  .time-space {
      width: 32vw;
      margin: 5.33333vw auto 2.66667vw
  }
  .time-space p {
    margin-bottom: 1.06667vw;
    background: transparent;
    border: 0.26667vw solid #2789CE;
    border-radius: 3.4vw;
    font-size: 5.0vw;
    margin-left: -10px;
    line-height: 10.66667vw;
  }
  .time-space span {
      font-size: 3.73333vw;
      line-height: 5.33333vw
  }
}

@media only screen and (max-device-width: 1024px) and (orientation: landscape) {
  body {
      font-size: 2.3988vmax;
      line-height: 2.9985vmax
  }
  .maintain-content {
      padding: 4.7976vmax 1.1994vmax;
      font-size: 2.3988vmax;
      line-height: 2.9985vmax
  }
  .maintain-content h2 {
      margin-bottom: 2.3988vmax;
      font-size: 3.5982vmax;
      line-height: 3.5982vmax
  }
  .time-space {
      width: 17.991vmax;
      margin: 2.9985vmax auto 1.49925vmax
  }
  .time-space p {
      margin-bottom: 0.5997vmax;
      background: transparent;
      border: 0.14993vmax solid #2789CE;
      border-radius: 3.5982vmax;
      font-size: 3.5982vmax;
      line-height: 5.997vmax
  }
  .time-space span {
      font-size: 2.09895vmax;
      line-height: 2.9985vmax
  }
}